import React from 'react'
const Triangle = ({ extraCss }) => {
  return (
    <svg
      css={extraCss}
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'>
      <path d='M0,0H30L0,30Z' fill='currentColor' />
    </svg>
  )
}

export default Triangle
