import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gTitle, gSubtitle } from '../../../style/config/globals'
import Triangle from '../../../assets/Triangle'
import ButtonOutline from '../../../components/ButtonOutline'
import { colors, s, globals, alpha } from '../../../style'

const ServiceThumbnail = ({ headlines, image, slug, lang, prefix }) => {

  const btn = {
    pl: 'Dowiedz się więcej',
    en: 'Read more'
  }
  
  return (
    <Link to={`${prefix}/${slug}`} css={sInner}>
      <GatsbyImage
        image={getImage(image)}
        alt={image.title}
        css={globals.absolute}
        imgStyle={{
          objectPosition: 'center center',
          transition: 'all 0.3s ease-out',
        }}
        imgClassName='service-image'
      />
      <div css={sGradient} />
      <span css={gSubtitle}>{headlines[0]}</span>
      <h3 css={[gTitle, sTitle]}>{headlines[1]}</h3>
      <ButtonOutline mix>
        {btn[lang]}
      </ButtonOutline>
      <Triangle
        extraCss={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          color: colors.main,
          transform: 'scale(-1)',
        }}
      />
    </Link>
  )
}

const gap = 50

const sInner = {
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  width: '100%',
  // padding: '50px 15px',
  [s.xs]: {
    // maxWidth: '100%',
    // flexBasis: '100%',
    // aspectRatio: '16/9',
    padding: '50px 15px',
  },
  [s.sm]: {
    maxWidth: '100%',
    flexBasis: '100%',
    // aspectRatio: '16/9',
    padding: '50px 30px',
  },
  [s.md]: {
    // aspectRatio: '21/9',
    padding: '100px 30px',
  },
  [s.lg]: {
    // maxWidth: `calc(50% - ${((2 - 1) * gap) / 2}px)`,
    // flexBasis: `calc(50% - ${((2 - 1) * gap) / 2}px)`,
    padding: '100px 60px',
  },
  '&:hover': {
    '.service-image': { transform: 'scale(1.2)' },
  },
  position: 'relative',
}

const sGradient = {
  ...globals.absolute,
  background: `linear-gradient(to bottom, 
    ${colors.container.concat(alpha[90])} 0%,
    ${colors.container.concat(alpha[60])} 50%,
    ${colors.container.concat(alpha[0])} 100%
    )`,
}

const sTitle = {
  fontSize: '3rem'
}

const sButton = {
  zIndex: '11',
}

export default ServiceThumbnail
