import React from 'react'
import Layout from '../components/Layout'
import Industry from '../page/Industry/Industry'

const IndustryPage = () => (
  <Layout>
    <Industry />
  </Layout>
)

export default IndustryPage
