import React from 'react'
import Seo from '../../components/Seo'
import { useLangContext } from '../../context/lang.context'
import FormService from '../Services/FormService/FormService'
import IntroSection from './IntroSection/IntroSection'
import { seo } from './seo'
// import BannerSection from '../Index/BannerSection/BannerSection'
import IndustriesAll from './IndustriesAll/IndustriesAll'

const Industry = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />
      <IndustriesAll />
      <FormService />
      {/* <BannerSection largeWidth /> */}
    </>
  )
}

export default Industry
