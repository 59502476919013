export const introSectionContent = {
  title: {
    pl: 'Transport Międzynarodowy',
    en: 'Industries & Products',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: 'Dedykowany transport i usługi logistyczne dla branż przemysłu i konkretnych rodzajów produktów. Wyceń transport swojego towaru i skorzystaj z oferty CARGONITE.',
    en: 'Dedicated transport and logistics services for industries and specific types of products. Evaluate the transport of your goods and take advantage of the CARGONITE offer.',
  },
}
